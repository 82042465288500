<template>
  <v-card color="primary" class="mt-2">
    <v-card-text class="px-2 break-all">
      <div>
        <a class="text--secondary break-all" :href="state.jobLink" target="_blank">{{state.jobLink}}</a>
      </div>
      <div>
        <VueQr :dotScale=".5" :text="state.jobLink" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VueQr from 'vue-qr'

export default {
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  components: {
    VueQr
  }
}
</script>

<style scoped>
.break-all {
  word-break: break-all;
}
</style>
